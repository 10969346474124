<template>
  <div class="main">
    <b-tabs
      v-model="tabIndex"
    >
      <b-tab
        :title="$t('Report')"
      >
        <template #title>
          <strong style="font-weight: 500; color: black">{{ $t("Transactions") }}</strong>
        </template>
        <div
          v-if="!showDashboard"
          style="display: flex; align-items: center; margin: 10px 0px"
        >
          <b-form-group
            id="input-group-8"
            :label="$t('TransactionType')"
            style="width:15%;  margin-left: 6px; margin-right: 6px;"
            label-for="input-25"
          >
            <vue-select
              id="input-6"
              v-model="typeTransaction"
              :options="transactionType"
              :reduce="(e) => e.value"
              label="name"
              :clearable="false"
              :placeholder="$t('Select')"
            /></b-form-group>
          <b-form-group
            id="input-group-8"
            :label="$t('EditY')"
            style="width:15%;  margin-left: 0px; margin-right: 6px;"
            label-for="input-25"
          >
            <vue-select
              id="input-5"
              v-model="year"
              required
              :clearable="false"
              :options="yearsArrays"
              :placeholder="$t('Select')"
              aria-describedby="input-1-live-feedback"
            />
          </b-form-group>
          <b-form-group
            id="input-group-8"
            :label="$t('Month')"
            style="width:15%"
            label-for="input-25"
          >
            <vue-select
              id="input-5"
              v-model="month"
              :reduce="(e) => e.value"
              required
              :clearable="false"
              :options="months"
              label="name"
              :placeholder="$t('Select')"
              aria-describedby="input-1-live-feedback"
            />
          </b-form-group>
          <button
            class="button"
            style="margin: 22px 0px 0px 10px"
            @click="showAccountDashboard"
          >
            {{ $t('GroupByAccount') }}
          </button>
          <!-- <div style="display: flex; gap: 5px; margin: 22px 0px 0px 10px;">
            <div style="background: pink; width: 25px; height: 25px; border-radius: 100%" />
            <div>{{ $t('GroupByAccount') }}</div>
          </div> -->
          <div style="display: flex; margin-left: 10px; margin-top: 22px;">
            <div
              class="legend"
              style="background-color: #ECFDF3; color: green;"
            >
              {{ $t('Debit') }}
            </div>
            <div
              class="legend"
              style="background-color: #FEF3F2; color: red;"
            >
              {{ $t('Credit') }}
            </div>
          </div>
        </div>

        <div
          v-if="showDashboard"
        >
          <div style="display: flex; gap: 20px; margin-top: 10px; align-items: flex-end;">
            <button
              class="editButton"
              @click="showDashboard = false"
            >
              {{ $t('Back') }}
            </button>
            <div class="year">
              <button
                class="nextweek"
                @click="previousWeek"
              >
                <b-icon-arrow-left />
              </button>
              <b-form-group
                :label="$t('From:')"
              >
                <date-picker
                  v-model="selectedDate"
                  value-type="format"
                  class="form-control"
                  format="DD-MM-YYYY"
                  type="week"
                  :placeholder="$t('SelectD')"
                  style="width: 193px;"
                  :clearable="false"
                  :lang="lang"
                />
              </b-form-group>
              <button
                class="nextweek"
                style="margin-left: 5px;"
                @click="nextWeek"
              >
                <b-icon-arrow-right />
              </button>
              <b-form-group
                :label="$t('To:')"
                style="margin-left: 10px; margin-bottom: 10px;"
              >
                <span style="border: 1px solid lightgray; padding: 10px 15px; border-radius: 8px">{{ toDate }}</span>
              </b-form-group>
            </div>
          </div>
          <div style="display: flex; gap: 2%; margin: 1.2% 0%; flex-wrap: wrap">
            <div
              v-for="account in getAccountDropDown"
              :key="account.accountId"
            >
              <div
                style="border: 1px solid #DDE0E5; border-radius: 6px; width: fit-content; padding: 20px; margin-bottom: 5%;"
                @click="selectAccount(account.accountId)"
              >
                <p style="font-size: 17px; font-weight: 600; color: #242F6E;">
                  {{ account.name }}
                </p>
                <div
                  style="margin: 5% 0%; border: 1px solid #DDE0E5"
                >
                  <Bar
                    :chart-options="barChartOptions"
                    :chart-data="getChartDataForAccount(account.accountId)"
                    :chart-id="barChartId"
                    :dataset-id-key="barDatasetIdKey"
                    :css-classes="barCssClasses"
                    :styles="barStyles"
                    :width="400"
                    :height="barHeight"
                    style="padding: 50px"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <button
            v-if="showAccountTransactions && !showDashboard"
            class="editButton"
            style="width: 150px !important;"
            @click="showAllTransactions"
          >
            {{ $t('ShowAll') }}
          </button>
        </div>
        <div
          v-if="!showDashboard"
          style="width: 100%;padding-top: 16px; margin-top: 0px;height: 74vh; overflow-y: scroll"
        >
          <table
            ref="exportable_table"
            class="team_table"
          >
            <thead style="position: sticky; top: -17px">
              <tr>
                <th>
                  {{ $t('AccountName') }}
                </th>
                <th style="text-align: center">
                  {{ $t('Amount') }}
                </th>
                <th style="text-align: center">
                  {{ $t('TaxRate') }}
                </th>
                <th>
                  {{ $t('Created') }}
                </th>
                <th>
                  {{ $t('Description') }}
                </th>
                <th>
                  {{ $t('ExecutorName') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(transaction, index) in getTransactionDetails"
                :key="index"
                @click="selectAccount(transaction.accountInd)"
              >
                <td
                  style="cursor: pointer"
                >
                  <span
                    v-b-tooltip.hover.right
                    :title="$t('FilterByAccount')"
                  >{{ transaction.accountName }}</span>
                </td>
                <td :style="transaction.transactionType == 'Debit' ? 'background-color: #ECFDF3; text-align: center; color: green;' : 'background-color: #FEF3F2; text-align: center; color: red;'">
                  {{ numbersWithcomma(transaction.amount) }} {{ transaction.currency }}
                </td>
                <td style="text-align: center;">
                  {{ transaction.taxRate }}%
                </td>
                <td>{{ convertUTCDateToLoacalDate(transaction.created) }}</td>
                <td>{{ transaction.notes }}</td>
                <td>{{ transaction.executorFullName }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          v-if="getTotalItemsForTransactions > 15"
        >
          <b-pagination
            v-if="!showDashboard"
            v-model="pageNumber"
            :total-rows="getTotalItemsForTransactions"
            :per-page="pageSize"
            first-number
            last-number
            align="fill"
            prev-class="prev-itemi"
            next-class="next-itemi"
            class="mt-1 mb-0"
          >
            <template #prev-text>
              <span class="previousOrNext"><b-icon-arrow-left />{{ $t('Previous') }}</span>
            </template>
            <template #next-text>
              <span class="previousOrNext">{{ $t('Next') }}<b-icon-arrow-right /></span>
            </template>
          </b-pagination>
        </div>
      </b-tab>
      <b-tab :title="$t('IncomeExpense')">
        <template #title>
          <strong style="font-weight: 500; color: black">{{ $t("IncomeExpense") }}</strong>
        </template>
        <div style="display: flex; gap: 1%; margin-bottom: 1%; align-items: flex-end">
          <button
            v-b-modal.add-cost-revenue
            class="button"
            style="width: 13%; margin-top: 1.5%;"
          >
            <b-icon-plus />
            {{ $t('AddCostRevenue1') }}
          </button>
          <b-form-group
            :label="$t('StartDate')"
          >
            <date-picker
              v-model="from"
              value-type="format"
              :placeholder="$t('SelectD')"
              :format="'YYYY-MM-DD'"
              :clearable="false"
              :lang="lang"
            />
          </b-form-group>
          <b-form-group
            :label="$t('EndDate')"
          >
            <date-picker
              v-model="to"
              value-type="format"
              :placeholder="$t('SelectD')"
              :format="'YYYY-MM-DD'"
              :clearable="false"
              :lang="lang"
            />
          </b-form-group>
          <div style="display: flex; align-items: center; margin: 0 15px;margin-top: 30px;">
            <b-form-radio
              v-model="selection"
              :value="true"
            >
              <span style="margin: 5px">

                {{ $t("Paid") }}
              </span>

            </b-form-radio>
            <b-form-radio
              v-model="selection"
              :value="false"
              style="margin-left: 10px"
            >
              <span
                style="margin: 5px"
              >

                {{ $t("NotPaid") }}
              </span>

            </b-form-radio>
          </div>
        </div>
        <div style="width: 100%;padding-top: 16px; margin-top: 0px;height: 74vh; overflow-y: scroll">
          <table
            ref="exportable_table"
            class="team_table"
          >
            <thead style="position: sticky; top: -17px">
              <tr>
                <th>
                  {{ $t('Created') }}
                </th>
                <th>
                  {{ $t('TotalPrice') }}
                </th>
                <th>
                  {{ $t('PaidPrice') }}
                </th>
                <th>
                  {{ $t('Description') }}
                </th>
                <th>
                  {{ $t('GenerateInvoice') }}
                </th>
                <th>
                  {{ $t('RemainingPayment') }}
                </th>
                <th>
                  {{ $t('Transactions') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(income, index) in getIncomeExpenseCost"
                :key="index"
              >
                <td>
                  {{ convertUTCDateToLoacalDate(income.created) }}
                </td>
                <td>
                  {{ numbersWithcomma(income.price) }}
                </td>
                <td>
                  {{ numbersWithcomma(income.paidAmount) }}
                </td>
                <td>
                  {{ income.paymentReason }}
                </td>
                <td v-b-modal.view-invoice>
                  <img
                    v-b-tooltip="$t('ViewInvoice')"
                    src="../../assets/images/documentLogo.svg"
                    style="width:30px; height:30px"
                    @click="showInvoices(income.incomeExpenseId)"
                  >
                </td>
                <td>
                  <button
                    v-if="income.price > income.paidAmount"
                    v-b-modal.pay-expenses
                    class="editButton"
                    @click="incomeId(income.incomeExpenseId)"
                  >
                    {{ $t('Pay') }}
                  </button>
                </td>
                <td
                  v-b-toggle.transaction-details
                  @click="goToTransactionDetails(income.transactionIds)"
                >
                  <button class="editButton">
                    {{ $t('View') }}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="getIncomeExpenseCostTotalItems > 15">
            <b-pagination
              v-model="itemsCost.pageNumber"
              :total-rows="getIncomeExpenseCostTotalItems"
              :per-page="itemsCost.pageSize"
              first-number
              last-number
              align="fill"
              prev-class="prev-itemi"
              next-class="next-itemi"
              class="mt-1 mb-0"
            >
              <template #prev-text>
                <span class="previousOrNext"><b-icon-arrow-left />{{ $t('Previous') }}</span>
              </template>
              <template #next-text>
                <span class="previousOrNext">{{ $t('Next') }}<b-icon-arrow-right /></span>
              </template>
            </b-pagination>
          </div>
        </div>
      </b-tab>
      <b-tab :title="$t('CostOfSoldGoods')">
        <template #title>
          <strong style="font-weight: 500; color: black">{{ $t("CostOfSoldGoods") }}</strong>
        </template>
        <div>
          <CostOfSoldGoods />
        </div>
      </b-tab>
      <b-tab>
        <template #title>
          <strong style="font-weight: 500; color: black">{{ $t("CostCard") }}</strong>
        </template>
        <div>
          <CostCard />
        </div>
      </b-tab>
      <b-tab>
        <template #title>
          <strong style="font-weight: 500; color: black">{{ $t("PurchaseBook") }}</strong>
        </template>
        <div>
          <CartPurchaseBook />
        </div>
      </b-tab>
    </b-tabs>
    <div
      v-if="isSidebarOpen"
      class="backdrop"
    />
    <AddCostRevenue @addCost="addCost" />
    <PayExpenses
      :income-expense-id="itemsCost.incomeExpenseId"
      @addPayment="addPaymentModal"
    />
    <TransactionDetails
      :transaction-id="transactionId"
      @onCancelToggle="onCancelToggle"
    />
    <ViewInvoice
      :invoices-document="invoicesDocument"
    />
  </div>
</template>

<script>
import AddCostRevenue from '@/components/employeePayments/modals/AddCostRevenue.vue'
import PayExpenses from '@/components/employeePayments/modals/PayExpenses.vue'
import ViewInvoice from '@/components/teams/modals/ViewInvoice.vue'
import TransactionDetails from '@/components/employeePayments/modals/TransactionDetails.vue'
import CartPurchaseBook from '@/components/cart/CartPurchaseBook.vue'
import CostCard from '@/components/cart/CostCard.vue'
import CostOfSoldGoods from '@/components/cart/CostOfSoldGoods.vue'
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { Bar } from 'vue-chartjs/legacy';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  RadialLinearScale,
} from 'chart.js';
import { client } from '../../domainConfig'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  RadialLinearScale,
  ArcElement,
);

export default {
  components: {
    AddCostRevenue,
    PayExpenses,
    TransactionDetails,
    DatePicker,
    Bar,
    ViewInvoice,
    CartPurchaseBook,
    CostCard,
    CostOfSoldGoods,
  },
  data() {
    return {
      toDate: null,
      tabIndex: null,
      isSidebarOpen: false,
      selectedDate: moment().format('DD-MM-YYYY'),
      selection: true,
      client: client.clientName,
      accountId: null,
      year: moment().year(),
      month: moment().month() + 1,
      typeTransaction: 'ALL',
      pageNumber: 1,
      pageSize: 15,
      from: moment().format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD'),
      totali: 0,
      totaliPaid: 0,
      showCounter: null,
      showDashboard: false,
      selectedAccount: null,
      selectedAccountId: null,
      showAccountTransactions: false,
      invoicesDocument: [],
      supplierId: null,
      supplierName: null,
      contactName: null,
      isActive: true,
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
      },
      itemsCost: {
        incomeExpenseId: null,
        created: null,
        price: null,
        paidAmount: null,
        paymentReason: null,
        compeletedPayment: true,
        pageNumber: 1,
        pageSize: 15,
        transactionIds: {
          transactionId: null,
        },
        fileUrls: [],
      },
      barChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
      barChartData: {
        labels: [],
        datasets: [],
      },
      barChartId: 'bar-chart',
      barDatasetIdKey: 'label',
      barCssClasses: '',
      barStyles: {},
      barWidth: 100,
      barHeight: 150,
      transactionType: [
        { value: 'ALL', name: this.$t('All') },
        { value: 'Debit', name: 'Debit' },
        { value: 'Credit', name: 'Credit' },
      ],
      months: [
        {
          value: 1,
          name: this.$t('January'),
        },
        {
          value: 2,
          name: this.$t('February'),
        },
        {
          value: 3,
          name: this.$t('March'),
        },
        {
          value: 4,
          name: this.$t('April'),
        },
        {
          value: 5,
          name: this.$t('May'),
        },
        {
          value: 6,
          name: this.$t('June'),
        },
        {
          value: 7,
          name: this.$t('July'),
        },
        {
          value: 8,
          name: this.$t('August'),
        },
        {
          value: 9,
          name: this.$t('September'),
        },
        {
          value: 10,
          name: this.$t('October'),
        },
        {
          value: 11,
          name: this.$t('November'),
        },
        {
          value: 12,
          name: this.$t('December'),
        },
      ],
      header: [
        {
          created: 'Created', totalToPay: 'TotalToPay', orderCartTrackingIdMessageDtos: { orderNumber: 'Order Number', notes: 'Notes', totalPaidAmount: 'TotalPaidAmount' },
        },
      ],
      objectPDF: {
        from: null,
        to: null,
        supplierId: null,
      },
    }
  },
  computed: {
    ...mapGetters([
      'getTotalItemsForTransactions',
      'getLoggedInUser',
      'getCalculationCosg',
      'getTransactionDetails',
      'getCalculateCostGoodsSold',
      'getCostOfGoodsSoldPDF',
      'getIncomeExpenseCost',
      'getIncomeExpenseByTransactionId',
      'getDetailsByTransactionId',
      'getAccountDropDown',
      'getReportGroupAccount',
      'getTotalPagesCalculateCogs',
      'getTotalItemsCalculateCogs',
      'getCardBySupplier',
      'getOtherSuppliers',
      'getSuppliers',
      'getCardBySupplierPDF',
      'getIncomeExpenseCostTotalItems',
    ]),
    calculateTransactionsByAccount() {
      const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
      const accountTotals = this.getReportGroupAccount.reduce((acc, transaction) => {
        const {
          accountId, accountName, amountPerDay, day, transactionType,
        } = transaction;

        if (!acc[accountName]) {
          acc[accountName] = {
            accountId,
            accountName,
            totalAmountPerDay: {
              Debit: Array(7).fill(0),
              Credit: Array(7).fill(0),
            },
          };
        }
        const date = new Date(this.year, this.month - 1, day); // Adjust month as JS Date month is 0-indexed
        const dayOfWeek = (date.getDay() + 6) % 7; // Adjust to have Monday as 0 and Sunday as 6

        acc[accountName].totalAmountPerDay[transactionType][dayOfWeek] += amountPerDay;

        return acc;
      }, {});

      // Ensure each account has an entry for each day, even if they have no transactions
      Object.values(accountTotals).forEach((account) => {
        ['Debit', 'Credit'].forEach(type => {
          if (account.totalAmountPerDay[type].length < 7) {
            const missingDays = 7 - account.totalAmountPerDay[type].length;
            account.totalAmountPerDay[type] = account.totalAmountPerDay[type].concat(Array(missingDays).fill(0));
          }
        });
      });

      return Object.values(accountTotals).map((account) => {
        return {
          ...account,
          daysOfWeek,
        };
      });
    },

    yearsArrays() {
      const result = [];
      const baseYear = this.year || moment().year();

      for (let i = -3; i < 4; i++) {
        result.push(baseYear + i);
      }

      return result;
    },
    returnWeek() {
      const sortedArray = [...this.getReportGroupAccount];
      sortedArray.sort((a, b) => { return a.week - b.week });
      return sortedArray;
    },
  },
  watch: {
    // tabIndex(value) {
    //   if (value == 1) {
    //     this.clearIncome()
    //   }
    // },
    selectedDate(value) {
      const prevDay = moment(value, 'DD-MM-YYYY').add(7, 'days');
      const thisDate = moment(value, 'DD-MM-YYYY').format('YYYY-MM-DD')
      this.toDate = moment(prevDay).format('DD-MM-YYYY')
      this.reportGroupByAccount({
        from: moment(thisDate).format('YYYY-MM-DD'),
        to: moment(prevDay).format('YYYY-MM-DD'),
      });
    },
    getReportGroupAccount: {
      handler() {
        if (this.getReportGroupAccount) {
          this.updateChartData();
        }
      },
    },
    month(value) {
      this.transactionByAccount({
        accountId: this.accountId, transaction: 'ALL', year: this.year, month: value, pageNumber: this.pageNumber, pageSize: 15,
      })
    },
    year(value) {
      this.transactionByAccount({
        accountId: this.accountId, transaction: 'ALL', year: value, month: this.month, pageNumber: this.pageNumber, pageSize: 15,
      })
    },
    pageNumber(value) {
      if (this.showAccountTransactions == false && this.tabIndex == 0) {
        this.transactionByAccount({
          accountId: null, transaction: 'ALL', year: this.year, month: this.month, pageNumber: value, pageSize: 15,
        })
      } else if (this.showAccountTransactions == true && this.tabIndex == 0) {
        this.transactionByAccount({
          accountId: this.selectedAccountId, transaction: 'ALL', year: this.year, month: this.month, pageNumber: value, pageSize: 15,
        })
      }
    },
    'itemsCost.pageNumber': function (value) {
      this.incomeExpensesCost({
        from: this.from, to: this.to, pageNumber: value, pageSize: this.pageSize, compeletedPayment: this.selection,
      })
    },
    typeTransaction(value) {
      this.transactionByAccount({
        accountId: this.accountId, transaction: value, year: this.year, month: this.month, pageNumber: this.pageNumber, pageSize: 15,
      })
    },
    selection(value) {
      this.incomeExpensesCost({
        from: this.from,
        to: this.to,
        compeletedPayment: value,
        pageNumber: this.itemsCost.pageNumber,
        pageSize: this.itemsCost.pageSize,
      })
    },

    from(value) {
      console.log('fromi', value)
      this.objectPDF.from = value
      if (value && this.to) {
        this.incomeExpensesCost({
          from: value, to: this.to, pageNumber: this.pageNumber, pageSize: this.pageSize, compeletedPayment: this.itemsCost.compeletedPayment,
        })
      }
    },
    to(value) {
      this.objectPDF.to = value
      if (value && this.from) {
        this.incomeExpensesCost({
          from: this.from, to: value, pageNumber: this.pageNumber, pageSize: this.pageSize, compeletedPayment: this.itemsCost.compeletedPayment,
        })
      }
    },
    supplierId(value) {
      this.objectPDF.supplierId = value
    },
  },
  mounted() {
    this.incomeExpensesCost({
      from: moment().format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD'),
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      compeletedPayment: this.itemsCost.compeletedPayment,
    })
    // this.calculationOfCosg({ from: moment().format('YYYY-MM-DD'), to: moment().format('YYYY-MM-DD') })
    this.transactionByAccount({
      accountId: null, transaction: 'ALL', year: moment().format('YYYY'), month: this.month, pageNumber: this.pageNumber, pageSize: this.pageSize,
    })
    const newDate = moment(this.selectedDate, 'DD-MM-YYYY').add(7, 'days')
    this.toDate = moment(newDate).format('DD-MM-YYYY')
    this.reportGroupByAccount({
      from: moment(this.selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      to: moment(newDate).format('YYYY-MM-DD'),
    });
    this.loadSuppliers({
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      supplierName: this.supplierName,
      contactName: this.contactName,
      isActive: this.isActive,
    });
  },
  methods: {
    ...mapActions(['calculationOfCosg',
      'transactionByAccount',
      'addCostRevenue',
      'calculateCostOfGoodsSold',
      'loadCostGoodsSoldPDF',
      'incomeExpensesCost',
      'detailsByTransactionId',
      'incomeExpensesCost',
      'incomeExpenseCostByTransactionId',
      'detailsByTransactionIds',
      'addCostToOpenRevenue',
      'clearIncome',
      'reportGroupByAccount',
      'incomeExpenseFileUrls',
      'calculateCostCardBySupplier',
      'loadSuppliers',
      'calculateCostCardBySupplierPDF',
    ]),
    // selectAccountOnDashboard(accountId) {
    //   this.selectedAccountId = accountId;
    //   this.showAccountTransactions = true;
    // }
    showInvoices(value) {
      this.incomeExpenseFileUrls({ incomeExpenseCostId: value })
    },
    onCancelToggle() {
      this.isSidebarOpen = false;
    },
    previousWeek() {
      const newDate = moment(this.selectedDate, 'DD-MM-YYYY').subtract(7, 'days');
      this.selectedDate = moment(newDate).format('DD-MM-YYYY');
      // this.updateDateRange(newDate);
    },
    nextWeek() {
      const newDate = moment(this.selectedDate, 'DD-MM-YYYY').add(7, 'days');
      this.selectedDate = moment(newDate).format('DD-MM-YYYY');
      // this.updateDateRange(newDate);
    },
    updateDateRange(date) {
      const from = date.startOf('week').format('MM-DD-YYYY');
      const to = date.endOf('week').format('MM-DD-YYYY');
      this.reportGroupByAccount({
        from,
        to,
      });
    },
    selectAccount(accountId) {
      this.selectedAccountId = accountId;
      this.showAccountTransactions = true;
      this.showDashboard = false;
      this.transactionByAccount({
        accountId: this.selectedAccountId, transaction: 'ALL', year: this.year, month: this.month, pageNumber: 1, pageSize: this.pageSize,
      })
      this.pageNumber = 1
    },
    showAllTransactions() {
      this.showAccountTransactions = false;
      this.transactionByAccount({
        accountId: null, transaction: 'ALL', year: this.year, month: this.month, pageNumber: 1, pageSize: this.pageSize,
      })
      this.pageNumber = 1
    },
    showAccountDashboard() {
      this.showDashboard = true;
    },
    updateChartData() {
      const transactions = this.calculateTransactionsByAccount;
      const datasets = transactions.map((account) => {
        return {
          label: account.accountName,
          backgroundColor: this.getRandomColor(),
          data: account.totalAmountPerDay,
        };
      });

      this.barChartData.labels = transactions[0].daysOfWeek;
      this.barChartData.datasets = datasets;
    },
    getRandomColor() {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    getChartDataForAccount(accountId) {
      const transactions = this.calculateTransactionsByAccount;
      const accountTransaction = transactions.find((transaction) => { return transaction.accountId === accountId });

      if (!accountTransaction) {
        return {
          labels: [],
          datasets: [],
        };
      }

      return {
        labels: accountTransaction.daysOfWeek,
        datasets: [
          {
            label: `${this.$t('Total')} Debit`,
            backgroundColor: 'green',
            data: accountTransaction.totalAmountPerDay.Debit,
          },
          {
            label: `${this.$t('Total')} Credit`,
            backgroundColor: 'red',
            data: accountTransaction.totalAmountPerDay.Credit,
          },
        ],
      };
    },
    incomeId(value) {
      this.itemsCost.incomeExpenseId = value
    },
    calculateTotalPrice(items) {
      return items.reduce((sum, item) => { return sum + item.totalPrice }, 0);
    },
    callDetails() {
      this.calculateCostOfGoodsSold({
        from: this.from,
        to: this.to,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      });
    },
    async addCamaping(obj) {
      await this.addCampaing({
        object: obj,
        successCallback: () => {
          this.campaingPagination({
            pageNumber: 1,
            pageSize: this.pageSize,
          });
        },
      })
    },
    async addCost(obj) {
      await this.addCostRevenue({
        object: obj,
        successCallback: () => {
          this.transactionByAccount({
            accountId: null, transaction: this.typeTransaction, year: this.year, month: moment().format('MM'), pageNumber: 1, pageSize: 15,
          })
        },
      })
    },
    async addPaymentModal(obj) {
      await this.addCostToOpenRevenue({
        object: obj,
        successCallback: () => {
          this.itemsCost.compeletedPayment = false
          this.incomeExpensesCost({
            compeletedPayment: this.itemsCost.compeletedPayment, from: this.itemsCost.from, to: this.itemsCost.to, pageNumber: 1, pageSize: 15,
          })
          this.reportGroupByAccount({
            from: this.itemsCost.from,
            to: this.itemsCost.to,
          });
        },
      })
    },
    transactionId(value) {
      this.transactionId = value.itemsCost.transactionIds.transactionId
    },
    goToTransactionDetails(item) {
      this.isSidebarOpen = true
      this.transactionId = item.map((value) => { return value.transactionId })
      this.detailsByTransactionIds({
        object: this.transactionId,
      })
    },
    numbersWithcomma(value) {
      const options = {
        style: 'decimal', // Other options: 'currency', 'percent', etc.
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      return value.toLocaleString('en-US', options);
    },
    convertUTCDateToLoacalDate(date) {
      const local = moment.utc(date).local().format('DD-MMM-YYYY h:mm A')
      return local
    },

    dateFormat(value) {
      return moment(value).format('DD-MM-YYYY, HH:mm')
    },
  },
}
</script>


<style scoped>
.legend {
  width: 100px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}

.nextweek {
    width: fit-content !important;
    height: 35px;
    display: flex;
    margin-right: 5px;
    margin-bottom: 7px;
    border-radius: 6px !important;
    justify-content: center;
    border: #c3d0dd 1px solid;
    background-color: #e9ecef;
    align-items: center;
}

.bigger-icon {
  font-size: 24px;
}
#buttonYes {
  display: flex;
  align-items: center;
  width: fit-content;
  height: 28px;
  background: #ECFDF3;
  color: #067647;
  font-weight: 500;
  font-size: 12px;
  border: 1px solid #ABEFC6;
  border-radius: 1000px;
  cursor: default;
}

.year {
  width: 100%;
  display: flex;
  align-items: flex-end;
}

#buttonNo {
  display: flex;
  align-items: center;
  width: fit-content;
  height: 28px;
  background: #FEF3F2;
  color: #B42318;
  font-weight: 500;
  font-size: 12px;
  border: 1px solid #FECDCA;
  border-radius: 1000px;
  cursor: default;
}

.hover-row td{
    padding: 9px;
    font-weight: 400;
    color: black;
}

.main{
    display: flex;
    flex-direction: column;
}


.team_table td {
  padding: 16px 9px !important;
}

.team_table thead {
  border-bottom: 1px solid #dcdfe6;
  color: #606266;
  text-align: left;
}

.team_table {
  width: 100%;
  font-size: 12px;
  text-align: left;
}

.hover-row {
  border-bottom: 1px solid #dcdfe6;
  color: #606266;
  text-align: left;
}

@media screen and (max-width: 1700px) {
  .main{
    padding: 75px 10px 15px 90px;
  }
}

@media screen and (max-width: 1200px) {
  .main{
    padding: 75px 10px 15px 10px;
  }
}
</style>
